@theme-color: #fff;
@font-color: #fff;
@font-size: 14px;
@sub-font-size: 12px;
@navbar-bgc: #292f40;
@layout-max-width: 1100px;
@nav-size-height: 60px;
@sidebar-bgc: #fff;
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  text-align: center;
  color: var(--color-text-2);
  font-size: @font-size;
}
