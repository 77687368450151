@theme-color: #fff;
@font-color: #fff;
@font-size: 14px;
@sub-font-size: 12px;
@navbar-bgc: #292f40;
@layout-max-width: 1100px;
@nav-size-height: 60px;
@sidebar-bgc: #fff;
.login-container {
  width: 100%;
  height: 100vh;
  background-image: url(../../../assets/image/loginBack.png);
  background-size: 100% 100%;
  position: relative;
  overflow: auto;

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 40px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;

    .content {
      display: flex;
      width: 1024px;
      height: 460px;
      background: #FFFFFF;
      box-shadow: 0px 4px 30px rgba(44, 91, 212, 0.1);
      border-radius: 2px;

      .form {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  background: #fff;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner {
  height: 100%;
  position: relative;
  width: 588px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: linear-gradient(163.85deg, #1d2129 0%, #00308f 100%);

  .logo {
    position: absolute;
    top: 20px;
    left: 10px;
    display: inline-flex;
    align-items: center;
    z-index: 1;

    &-text {
      margin-left: 4px;
      margin-right: 4px;
      font-size: 20px;
      color: @font-color;
    }
  }

  &-item {
    text-align: center;
    color: @font-color;
  }

  &-inner {
    background-image: url('../../../assets/mask-group.svg');
    background-size: cover;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }

  &-sub-title {
    margin-top: 8px;
    font-size: 14px;
    line-height: 22px;
  }

  &-image {
    margin-top: 30px;
    width: 320px;
  }
}

.login-form {
  &-wrapper {
    width: 320px;
    margin: 0 auto;
  }

  &-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  &-sub-title {
    font-size: 16px;
    line-height: 24px;
  }

  &-error-msg {
    height: 32px;
    line-height: 32px;
  }

  &-password-actions {
    display: flex;
    justify-content: space-between;
  }

  &-register-btn {}
}


.login_form_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;

  .login_form_container_left {
    width: 564px;
    height: 100%;
    background-image: url('../image/loginLeftBack.png');
    background-size: 100% 100%;
    font-family: 'PingFang SC';
    font-style: normal;
    color: #FFFFFF;
    font-weight: 400;

    div:nth-child(1) {
      font-size: 24px;
      margin: 32px 0 0 40px;
    }

    div:nth-child(2) {
      font-size: 36px;
      margin: 82px 0 0 105px;
    }

    div:nth-child(3) {
      font-size: 20px;
      line-height: 28px;
      margin-left: 105px;
    }

    div:nth-child(4) {
      font-size: 20px;
      line-height: 28px;
      margin-left: 105px;
    }
  }

  .login_form_container_right {
    width: 460px;
    height: 100%;
    box-sizing: border-box;
    padding: 78px 80px 0;

    .login_form_container_right_title {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      color: #1D2129;
      width: 100%;
      text-align: center;
      margin-bottom: 26px;
    }

    .btnClass {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
    }

    .forget_password {
      width: 100%;
      text-align: right;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #1664FF;
      margin-top: 12px;

      span {
        cursor: pointer;
      }
    }

    .codeBtn {
      padding-left: 12px;
      border-left: 1px solid #E5E8EF;

      .codeBtn_huoqu {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #1664FF;
        cursor: pointer;
      }

      .codeBtn_zhanshi {
        color: #ccc;
      }
    }

    .info_class {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #4E5969;
      margin-top: 12px;
      text-align: center;
      cursor: pointer;
    }

    .next_class {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #1664FF;
      margin-top: 12px;
      text-align: center;
      cursor: pointer;
    }
  }

  .login_form_container_bottom {
    width: 100%;
    text-align: center;
    margin-top: 12px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #4E5969;
  }

}




@media screen and (max-width: 1000px) {
  .banner {
    display: none;
  }
}