@theme-color: #fff;
@font-color: #fff;
@font-size: 14px;
@sub-font-size: 12px;
@navbar-bgc: #292f40;
@layout-max-width: 1100px;
@nav-size-height: 60px;
@sidebar-bgc: #fff;
* {
  margin: 0;
  padding: 0;
}

body,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
dl,
dt,
dd {
  font-size: 12px;
  font-weight: normal;
}

p {
  font-size: 14px;
}

.public-DraftStyleDefault-ltr {
  font-size: 14px;
}

ul {
  list-style: none;
}

img {
  border-style: none;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: var(--color-bg-1);
  overflow: hidden;
}

.chart-wrapper {
  .bizcharts-tooltip {
    background: linear-gradient(304.17deg,
        rgb(253 254 255 / 60%) -6.04%,
        rgb(244 247 252 / 60%) 85.2%) !important;
    border-radius: 6px;
    backdrop-filter: blur(10px);
    padding: 8px !important;
    width: 180px !important;
    opacity: 1 !important;
  }
}

body[arco-theme='dark'] {
  .chart-wrapper {
    .bizcharts-tooltip {
      background: linear-gradient(304.17deg,
          rgba(90, 92, 95, 0.6) -6.04%,
          rgba(87, 87, 87, 0.6) 85.2%) !important;
      backdrop-filter: blur(10px);
      border-radius: 6px;
      box-shadow: none !important;
    }
  }
}

body {

  .arco-menu-light.arco-menu-horizontal .arco-menu-item.arco-menu-selected:hover,
  .arco-menu-light.arco-menu-horizontal .arco-menu-group-title.arco-menu-selected:hover,
  .arco-menu-light.arco-menu-horizontal .arco-menu-pop-header.arco-menu-selected:hover,
  .arco-menu-light.arco-menu-horizontal .arco-menu-inline-header.arco-menu-selected:hover,
  .arco-menu-light.arco-menu-horizontal .arco-menu-pop-header.arco-menu-selected,
  .arco-menu-light .arco-menu-pop-header.arco-menu-selected .arco-icon {
    background: none;
    color: #fff;
  }

  .arco-menu-light.arco-menu-horizontal .arco-menu-item:hover {
    background: hsla(0, 0%, 100%, 0.15);
  }

  .arco-menu-light.arco-menu-horizontal .arco-menu-item {
    color: #fff;
  }

  .arco-menu-horizontal .arco-menu-item,
  .arco-menu-horizontal .arco-menu-group-title,
  .arco-menu-horizontal .arco-menu-pop-header,
  .arco-menu-horizontal .arco-menu-inline-header,
  .arco-menu-horizontal .arco-menu-pop-header:hover {
    color: #fff;
    background: none;
  }

  .arco-menu-horizontal .arco-menu-pop-header:hover {
    background: hsla(0, 0%, 100%, 0.15);
  }

  .arco-layout-sider-light {
    box-shadow: 0 5px 8px 0 rgb(0 0 0 / 15%);
  }

  .arco-form-inline .arco-form-item {
    margin-bottom: 0;
  }
}

.arco-menu-item {
  border-radius: 0 !important;
}

.arco-menu-selected {
  border-right: 2px solid #1664FF;
}

.arco-table-th-item-title {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #1D2129;
}

.arco-btn-primary {
  background-color: #1664FF !important;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.arco-input {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.arco-select-view-value {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}

.arco-select-size-default input {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
}

.arco-select-option {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
}

.arco-picker-size-default input {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
}

.arco-menu-vertical .arco-menu-inner {
  padding: 4px 0;
}

.arco-breadcrumb-item {
  padding: 0;
}

.arco-drawer-content {
  padding: 16px 20px !important;
}

.arco-input {
  height: 32px;
}

.arco-input-inner-wrapper {
  height: 32px;
}

.arco-table table thead tr:first-child th:first-child {
  border-radius: 0 !important;
}

.arco-table table thead tr:first-child th:last-child {
  border-radius: 0 !important;
}

.arco-table-container {
  border-radius: 0 !important;
}

.arco-btn-size-default {
  font-size: 12px !important;
}

.arco-btn-secondary:not(.arco-btn-disabled) {
  background-color: #f2f3f8;
}

.arco-drawer-header {
  padding: 0 20px;
}

.arco-form-item {
  margin-bottom: 16px;
}

.arco-drawer-footer {
  padding: 16px 20px;
}

.arco-table-td {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px !important;
  color: #1D2129 !important;
}

.shenglue {
  max-width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.smallShenglue {
  max-width: 60px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.shenglueLength {
  max-width: 380px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

li {
  font-size: 14px;
}

.arco-select-addbefore {
  color: #9098A9 !important;
}


.arco-table-body::-webkit-scrollbar {
  width: 3px;
}

.arco-table-body::-webkit-scrollbar-track {
  background-color: #E5E8EF;
}

.arco-table-body::-webkit-scrollbar-thumb {
  background: #979797;
  border-radius: 25px;
}

.bodyChaochu{
  width: 500px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  
}
.JoL-multifunction .tooltip{
  display: none !important;
}
// 族谱图需更改
.arco-form-label-item .arco-form-item-symbol svg {
  width: 12px !important;
}