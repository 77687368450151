@theme-color: #fff;
@font-color: #fff;
@font-size: 14px;
@sub-font-size: 12px;
@navbar-bgc: #292f40;
@layout-max-width: 1100px;
@nav-size-height: 60px;
@sidebar-bgc: #fff;
.layout {
  width: 100%;
  height: 100%;
}

.title {
  position: fixed;
  height: 30px;
  width: 220px;
  z-index: 100;
  margin-bottom: 4px;
  line-height: 40px;
  padding-left: 36px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.layout-navbar {
  position: fixed;
  width: 100%;
  min-width: @layout-max-width;
  top: 0;
  left: 0;
  z-index: 100;
  height: @nav-size-height;

  &-hidden {
    height: 0;
  }
}

.layout-sider {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  box-sizing: border-box;
  background: @sidebar-bgc;
  padding-top: 48px;

  ::-webkit-scrollbar {
    width: 12px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: var(--color-text-4);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-text-3);
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -1px;
    width: 1px;
    height: 100%;
    background-color: var(--color-border);
  }

  > :global(.arco-layout-sider-children) {
    overflow-y: hidden;
  }

  .collapse-btn {
    height: 24px;
    width: 24px;
    background-color: var(--color-fill-1);
    color: var(--color-text-3);
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    // 位置
    position: absolute;
    bottom: 12px;
    right: 12px;

    &:hover {
      background-color: var(--color-fill-3);
    }
  }
}

.menu-wrapper {
  overflow: auto;
  height: 100%;

  :global(.arco-menu-item-inner > a::after),
  :global(.arco-menu-item > a::after) {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  :global(.arco-menu-inline-header) {
    font-weight: 400;
  }
}

.icon {
  font-size: 18px;
  vertical-align: text-bottom;
  margin-right: 4px !important;
}

.icon-empty {
  width: 2px;
  // width: 12px;
  height: 18px;
  display: inline-block;
}

.layout-content {
  // background-color: var(--color-fill-2);
  background-color: #fff;
  min-width: @layout-max-width;
  min-height: 100vh;
  transition: padding-left 0.1s;
  box-sizing: border-box;
  // overflow: auto;
}

.layout-content-wrapper {
  // padding: 16px 24px;
  background-color: #fff;
  overflow: auto;

  .layout-content-wrapper-title {
    padding: 12px 0 16px 24px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    min-height: 90px;
    box-sizing: border-box;
    position: relative;
  }
  .layout-content-wrapper-title2 {
    padding: 12px 0  0 24px;
    box-sizing: border-box;
    position: relative;
  }

  .layout-content-wrapper-content {
    padding: 16px 24px;
    // overflow: auto;
    // height: 100vh;
    // padding-bottom: 80px;
  }
  .layout-content-wrapper-content_02 {
    margin-top: 48px;
  }
}

.layoutTitle {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #1D2129;
  margin-top: 16px;
  // padding-left: 4px;
}

.spin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - @nav-size-height);
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  height: 48px;
  background: #0838A8;
  z-index: 999;
  box-sizing: border-box;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .headerTitle {
    display: flex;
    align-items: center;
    cursor: pointer;

    div {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #FFFFFF;
    }
  }

  .headerUser {
    display: flex;
    align-items: center;
    cursor: pointer;

    div:nth-child(1) {
      width: 16px;
      height: 16px;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    div:nth-child(2) {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #FFFFFF;
      margin-left: 8px;
      max-width: 83px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .rightBtn {
    display: none;
    position: fixed;
    right: 0;
    top: 48px;
    width: 120px;
    height: 96px;
    background: #fff;
    /* 阴影/高度阴影-模态弹窗 */
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 0px 0px 2px 2px;
    padding: 4px 0;

    div {
      width: 100%;
      height: 50%;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #4E5969;
      cursor: pointer;
    }

    div:nth-child(2) {
      border-top: 1px solid #FFFFFF;
    }

    div:hover {
      background: #F2F3F8;
      color: #1664FF;
    }
  }

  .user_container {
    position: fixed;
    right: 0;
    width: 100px;
    height: 48px;
    background-color: transparent;
    cursor: pointer;
  }

  .user_container:hover+.rightBtn {
    display: block;
  }

  .rightBtn:hover {
    display: block;
  }
}

.bang_email {
  background: #FFFAE8;
  border-radius: 2px;
  box-sizing: border-box;
  padding: 8px 13px 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-left: -24px;
  margin-top: -12px;

  .bang_email_text {
    display: flex;
    align-items: center;

    img {
      width: 14.4px;
      height: 14.4px;
    }

    .bang_email_text_span1 {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #1D2129;
      margin-left: 8.8px;
    }

    .bang_email_text_span2 {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #1664FF;
      margin-left: 82px;
      cursor: pointer;
    }
  }

}